$(document).ready(function() {
    
    $('.panel-group').on('show.bs.collapse', function(e) {
        console.log($(e.target).prev('.panel-heading'));
        $(e.target).prev('.panel-heading').addClass('active');
    });

    $('.panel-group').on('hide.bs.collapse', function(e) {
        console.log($(e.target).prev('.panel-heading'));
        $(e.target).prev('.panel-heading').removeClass('active');
    });

});
